import React, { useState } from "react";
import PropTypes from "prop-types";
import Tooltip from "../../../common/Tooltip/Tooltip";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import ApplyForExemption from "../../Modals/ApplyForExemptionModal/ApplyForExemption";
import { GRAY } from "../diyConstants";
import { useStyles } from "./ExemptionLink.style";

const ExemptionLink = ({
  canApplyForExemption,
  isContributor,
  priceSelectStatus,
  townsWithClusters,
  envUrl,
  currentUserId,
  handleExemptionRequest,
}) => {
  const classes = useStyles();

  const [openApplyForExemptionModal, setOpenApplyForExemptionModal] =
    useState(false);
  const [openPostSubmitModal, setOpenPostSubmitModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const shouldShowExemptionLink = () => {
    if (isContributor && priceSelectStatus.nonProfit) {
      return true;
    } else {
      return false;
    }
  };

  const shouldEnableExemptionLink = () => {
    return canApplyForExemption;
  };

  const handleOpenExemption = () => {
    if (shouldEnableExemptionLink()) {
      setOpenApplyForExemptionModal(true);
    }
  };

  const handleCloseExemption = () => {
    setOpenApplyForExemptionModal(false);
  };

  return (
    <>
      {shouldShowExemptionLink() && (
        <Tooltip
          contents={
            <div
              className={classes.exemption_link}
              onClick={handleOpenExemption}
            >
              <Typography level="t4_text_4" color={GRAY} underlined>
                {labels.REQUEST_FINANCIAL_HARDSHIP_EXEMPTION}
              </Typography>
            </div>
          }
          title={
            <div
              className={
                shouldEnableExemptionLink()
                  ? classes.hidden
                  : classes.tooltip_text
              }
            >
              {labels.TOOLTIP_FOR_EXEMPTION_LINK}
            </div>
          }
          placement="bottom-start"
          arrow
          disableHoverListener={shouldEnableExemptionLink() ? true : false}
          disableFocusListener={shouldEnableExemptionLink() ? false : true}
        />
      )}

      <ApplyForExemption
        shouldOpenApplyForExemptionTownPickerModal={openApplyForExemptionModal}
        onCancel={handleCloseExemption}
        townsWithClusters={townsWithClusters}
        currentUserId={currentUserId}
        envUrl={envUrl}
        onRequestSent={handleExemptionRequest}
        openPostSubmitModal={openPostSubmitModal}
        setOpenPostSubmitModal={setOpenPostSubmitModal}
        successMessage={successMessage}
        setSuccessMessage={setSuccessMessage}
      />
    </>
  );
};

ExemptionLink.propTypes = {
  canApplyForExemption: PropTypes.bool,
  isContributor: PropTypes.bool,
  priceSelectStatus: PropTypes.shape({
    profit: PropTypes.bool,
    politicalCandidates: PropTypes.bool,
    nonProfit: PropTypes.bool,
    government: PropTypes.bool,
  }),
  townsWithClusters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      name_with_zip_code: PropTypes.string,
      cluster_towns: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.number,
          slug: PropTypes.string,
        })
      ),
    })
  ),
  envUrl: PropTypes.string,
  currentUserId: PropTypes.number,
  handleExemptionRequest: PropTypes.func,
};

export default ExemptionLink;
